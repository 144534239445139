import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider
} from 'react-router-dom'

import RootLayout from './layouts/RootLayout';
import WorksLayout from './layouts/WorksLayout';
import Home from './pages/Home'
import Works from './pages/Works'
import WorkDetail from './pages/WorkDetail'
import About from './pages/About'
import SonoraAI from './pages/SonoraAI'
import PrivacyPolicy from './pages/SonoraAI/privacy_policy'
import TermsAndConditions from './pages/SonoraAI/terms_and_conditions'
import EULA from './pages/SonoraAI/eula'
import Error from './pages/Error';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route
        path='work'
        element={<WorksLayout />}>
        <Route index element={<Works />} />
        <Route path=':name' element={<WorkDetail />} />
      </Route>
      <Route path='about' element={<About />} />
      <Route path='sonora-ai' element={<SonoraAI />} />
      <Route path='sonora-ai/privacy-policy' element={<PrivacyPolicy />} />
      <Route path='sonora-ai/terms-and-conditions' element={<TermsAndConditions />} />
      <Route path='sonora-ai/eula' element={<EULA />} />
      <Route path='*' element={<Error />} />
    </Route>
  )
)

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
